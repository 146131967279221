(function($) {
	window.Slider = function($slider, options) {
		var This = this;

		if ($slider.length == 0 ) {
			return;
		}

		var options = $.extend({}, {
			max: 6,
			minwidth: 300,

			onChange: null
		}, options);

		var $list = $('.slider__list', $slider);
		var $items = $('.slider__item', $slider);
		var $inner = $('.slider__inner', $slider);
		var $pager = $('.slider__pager', $slider);

		var pageritemclass = $pager.data('itemclass');

		var selitem = null;
		var selleft = null;

		var bypass = null
		var hovered = false;

		var info = {
			width: null,
			maxperpage: null,
			pages: null
		}

		function getItemIndexByPage(page) {
			var result = null;

			$items.each(function() {
				var $item = $(this);
				var ipage = $item.data('s_page');

				if (ipage == page) {
					result = $item.index();

					return false;
				}
			});

			return result;
		}

		function getItemIndexByLeft(left) {
			var size = $items.length;

			var sleft = left + info.width * 0.5;

			if (size > 1) {
				for (var i = 0; i < size; i++) {
					var $prev = $items.eq(i);
					var $next = $items.eq(i+1);

					var prev_left = $prev.data('s_left');
					var next_left = $next.data('s_left');

					if (i == 0 && prev_left > sleft) {
						return 0;
					}

					if ($next.length == 0) {
						return i;
					}

					if (prev_left <= sleft && next_left > sleft) {
						return i;
					}
				}
			} else {
				return 0;
			}

			return null;
		}

		function getPageByItemIndex(i) {
			var ip = Math.floor(i / info.maxperpage);

			if (info.pages > 1) {
				if (i >= $items.length - info.maxperpage) {
					ip = info.pages - 1;
				}
			}

			return ip;
		}

		function getPageByLeft(left) {
			var item = getItemIndexByLeft(-left);

			if (item !== null) {
				if (info.maxperpage !== null) {
					var size = $items.length;
					var page = item / info.maxperpage;

					var lastleft = size - Math.ceil(info.maxperpage / 2);

					if (item >= lastleft) {
						page = info.pages - 1;
					}

					return Math.floor(page);
				} else {
					return item;
				}
			}

			return null;
		}

		function goTo(left, force) {
			if (force) {
				$slider.removeClass('slider--anim');
				$list.css('transform', 'translateX(' + left + 'px)');
				$list[0].offsetHeight;
				$slider.addClass('slider--anim');
			} else {
				$list.css('transform', 'translateX(' + left + 'px)');
			}

			selleft = left;

			updatePagerSels();
		}

		function goToItem(i, force) {
			var size = $items.length;

			if (i < 0) {
				i = 0;
			}

			if (i >= size) {
				i = size - 1;
			}

			if (options.fixed) {
				if (i > size - info.maxperpage && size > info.maxperpage) {
					i = size - info.maxperpage;
				}
			}

			var $item = $items.eq(i);
			var left = -$item.data('s_left');

			selitem = i;

			goTo(left, force);

			if (options.onChange !== null && i !== null) {
				options.onChange(i);
			}
		}

		this.goToItem = function (i) {
			goToItem(i);
		}

		function goToPage(i, force) {
			if (i < 0) {
				i = 0;
			}

			if (i >= info.pages) {
				i = info.pages - 1;
			}

			var ii = getItemIndexByPage(i);

			goToItem(ii);
		}

		function prevPage() {
			var page = getPageByItemIndex(selitem);

			if (page > 0) {
				page = page - 1;
			} else {
				page = info.pages - 1;
			}

			goToPage(page);
		}

		function nextPage() {
			var page = getPageByItemIndex(selitem);
			var newpage = (page + 1) % info.pages;

			goToPage(newpage);
		}

		function nextItem() {
			var size = $items.length;
			var next = (selitem + 1 ) % size;

			if (options.fixed) {
				if (next > size - info.maxperpage) {
					next = 0;
				}
			}

			goToItem(next);
		}

		function getItemsByPage(page) {
			var $result = $();

			$items.each(function() {
				var $item = $(this);

				if ($item.data('s_page') == page) {
					$result = $result.add($item);
				}
			});

			return $result;
		}

		this.getMaxPerPage = function () {
			return info.maxperpage;
		}

		function updatePagerSels() {
			if ($pager.length > 0) {
				var page = getPageByLeft(selleft);
				var $pageritems = $('.slider__pager-item', $pager);

				var cls = 'slider__pager-item--selected';

				if (pageritemclass) {
					cls += ' ' + pageritemclass + '--selected';
				}

				$pageritems.removeClass(cls);
				$pageritems.eq(page).addClass(cls);
			}
		}

		function updatePager() {
			$pager.empty();

			for (var p = 0; p < info.pages; p++) {
				var $page = $('<div class="slider__pager-item">');

				if (pageritemclass) {
					$page.addClass(pageritemclass);
				}

				$page.click(function(e) {
					goToPage($(this).index());
				});

				$page.appendTo($pager);
			}

			updatePagerSels();
		}

		/*
		function initWidths() {
			$items.width('');
			// $list.width('10000px');

			width = $inner.width();

			if (options.autowidthmin) {
				options.autowidth = Math.floor(width / options.autowidthmin);
			}

			$items.each(function(i) {
				var $item = $(this);
				var iwidth;

				if (options.autowidth) {
					iwidth = Math.floor(width / options.autowidth);
					$item.width(iwidth);
				} else {
					iwidth = $item.width();

					if (iwidth > width) {
						iwidth = width;
						$item.width(iwidth);
					}
				}

				$item.data('s_width', iwidth);
			});

			// $list.width('');
		}
		*/

		function initVary() {
			throw "Not supported";

			// var total = 0;
			// var page = 0;
			// var inpage = 0;

			// $items.each(function(i) {
			// 	var $item = $(this);

			// 	var iwidth = $item.data('s_width');

			// 	total += iwidth;

			// 	if (total > width || options.max != null && inpage >= options.max ) {
			// 		page++;
			// 		inpage = 0;
			// 		total = iwidth;
			// 	}

			// 	$item.data('s_page', page);
			// 	$item.addClass('slider__item--p' + (page + 1) );

			// 	inpage++;
			// });

			// info.pages = page + 1;

			// for (var p = 0; p < pages; p++) {
			// 	var $pits = getItemsByPage(p);
			// 	var size = $pits.length;

			// 	var total = width;

			// 	$pits.each(function() {
			// 		var $item = $(this);
			// 		var iwidth = $item.data('s_width');

			// 		total -= iwidth;
			// 	});
			// }
		}

		function getItemsMaxWidth() {
			var maxwidth = null;

			$items.each(function(i) {
				// var width = $(this).data('s_width');
				var width = $(this).width();

				if (width > maxwidth) {
					maxwidth = width;
				}
			});

			return maxwidth;
		}

		function initFixed() {
			if (!options.max) {
				throw "max is not defined";
			}

			if (!options.minwidth) {
				throw "minwidth is not defined";
			}

			var ipp = options.max;
			var size = $items.length;
			info.width = $inner.width();

			var minipp = Math.floor(info.width / options.minwidth);

			if (ipp > minipp) {
				ipp = minipp;
			}

			if (ipp <= 0) {
				ipp = 1;
			}

			var iwidth = info.width / ipp;

			if (iwidth > info.width) {
				iwidth = info.width;
			}

			info.pages = Math.ceil(size / ipp);
			info.maxperpage = ipp;

			$items.each(function(i) {
				var $item = $(this);

				var page = Math.floor(i / ipp);
				var left = i * iwidth;

				$item.data('s_page', page);
				$item.data('s_left', left);

				$item.addClass('slider__item--p' + (page + 1));

				if (i % ipp == 0) {
					$item.addClass('slider__item--p-first');
				}

				if (i % ipp == ipp - 1 || i == size - 1) {
					$item.addClass('slider__item--p-last');
				}

				$item.css({
					'left': left,
					'width': iwidth
				});
			});
		}

		function updateHtml() {
			goTo(0, true);

			// initWidths();

			var pagesstr = '';

			for (var p = 1; p < 25; p++) {
				pagesstr += ' slider__item--p' + p;
			}

			$items.each(function(i) {
				$(this).removeClass(pagesstr + ' slider__item--p-last slider__item--p-first');
			});

			if (options.fixed) {
				initFixed();
			} else {
				initVary();
			}

			goToItem(selitem, true);

			updatePager();

			$slider.toggleClass('slider--onepage', info.pages == 1);
		}

		function initHtml() {
			$inner.css({
				'position': 'relative',
				'overflow': 'hidden',
				'width': '100%',
				'height': '100%'
			});

			$list.css({
				'position': 'absolute',
				'height': '100%'
			});

			$items.css({
				'position': 'absolute',
				'height': '100%'
			});

			$slider.addClass('slider--anim');
		}

		function initTimers() {
			if (!options.delay) {
				return;
			}

			window.setInterval(function() {
				if (bypass || hovered) {
					return;
				}

				if (options.step) {
					nextItem();
				} else {
					nextPage();
				}
			}, options.delay);
		}

		function initEvents() {
			$(window).resize(updateHtml);

			$('.slider__next', $slider).click(function() {
				nextPage();
			});

			$('.slider__prev', $slider).click(function() {
				prevPage();
			});

			var tx = null;
			var ton = false;
			var tleft = null;
			var newleft = null;

			$inner.on('touchstart', function(e) {
				tx = e.originalEvent.touches[0].clientX;

				ton = true;
				tleft = selleft;

				bypass = true;

				$slider.addClass('slider--drag');
			});

			$inner.on('touchmove', function(e) {
				if (!ton) {
					return;
				}

				var nx = e.originalEvent.touches[0].clientX;
				var delta = nx - tx;
				newleft = tleft + delta;

				var w2 = Math.round(info.width / 2);
				var maxleft = w2;
				var maxright = -(info.pages - 1) * info.width - w2;

				if (newleft > maxleft) {
					newleft = maxleft;
				} else if (newleft < maxright) {
					newleft = maxright;
				}

				goTo(newleft, true);
			});

			$inner.on('touchend', function(e) {
				if (!ton) {
					return;
				}

				var page = getPageByLeft(newleft);

				goToPage(page);

				ton = false;
				bypass = false;

				$slider.removeClass('slider--drag');
			});

			$slider.hover(
				function() {
					hovered = true;
				},

				function() {
					hovered = false;
				}
			);
		}

		function init() {
			initHtml();
			initEvents();
			initTimers();

			updateHtml();
			goToPage(0);

			$slider.addClass('slider--inited');
		}

		this.setOption = function(name, val) {
			options[name] = val;
		}

		init();
	}
})(jQuery);
