import * as CookieConsent from "vanilla-cookieconsent";

export default class PrivatePolicy {
    public static initCookieConsent(): void {
        CookieConsent.run({

            categories: {
                necessary: {
                    enabled: true,
                    readOnly: true
                },
                functional: {
                    enabled: true,
                },
                statistics: {},
                marketing: {}
            },

            language: {
                default: 'pl',
                translations: {
                    pl: {
                        consentModal: {
                            title: 'Na naszej stronie korzystamy z plików cookie',
                            description: 'Aby zapewnić jak najlepsze wrażenia, korzystamy z technologii, takich jak pliki cookie, do przechowywania i/lub uzyskiwania dostępu do informacji o urządzeniu. Zgoda na te technologie pozwoli nam przetwarzać dane, takie jak zachowanie podczas przeglądania lub unikalne identyfikatory na tej stronie. Brak wyrażenia zgody lub wycofanie zgody może niekorzystnie wpłynąć na niektóre cechy i funkcje.',
                            acceptAllBtn: 'Zaakceptuj',
                            acceptNecessaryBtn: 'Odrzuć',
                            showPreferencesBtn: 'Zarządzaj'
                        },
                        preferencesModal: {
                            title: 'Zarządzaj preferencjami cookies',
                            acceptAllBtn: 'Zaakceptuj wszystkie',
                            acceptNecessaryBtn: 'Odrzuć',
                            savePreferencesBtn: 'Zaakceptuj wybrane',
                            closeIconLabel: 'Zamknij',
                            sections: [
                                {
                                    title: 'Zarządzaj zgodą',
                                    description: 'Aby zapewnić jak najlepsze wrażenia, korzystamy z technologii, takich jak pliki cookie, do przechowywania i/lub uzyskiwania dostępu do informacji o urządzeniu. Zgoda na te technologie pozwoli nam przetwarzać dane, takie jak zachowanie podczas przeglądania lub unikalne identyfikatory na tej stronie. Brak wyrażenia zgody lub wycofanie zgody może niekorzystnie wpłynąć na niektóre cechy i funkcje.'
                                },
                                {
                                    title: 'Niezbędne',
                                    description: 'Pliki cookie niezbędne do prawidłowego funkcjonowania witryny.',
                                    linkedCategory: 'necessary'
                                },
                                {
                                    title: 'Funkcjonalne',
                                    description: 'Pliki cookie wymagane do prawidłowego działąnia części elementów na stronie. Nie są wymagane, ale zalecane.',
                                    linkedCategory: 'functional'
                                },
                                {
                                    title: 'Statystyka',
                                    description: 'Pliki cookie wykorzystywane do celów statystycznych.',
                                    linkedCategory: 'statistics'
                                },
                                {
                                    title: 'Marketing',
                                    description: 'Pliki cookie wykorzystywane do celów marketingowych.',
                                    linkedCategory: 'marketing'
                                }
                            ]
                        }
                    }
                }
            }
        });
    }
}
