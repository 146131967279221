import "../../modules/utils/utils/theme/js/sliders";
import "../../modules/media/svg/theme/js/jquery.svgInject";
import PrivatePolicy from "./utils/PrivatePolicy";

declare var Slider;

class Site {
	public static discover($parent?: JQuery): void {
		$('.partners-slider', $parent).each((i, el) => {
			new Slider($(el), {
				fixed: true,
				max: 5,
				minwidth: 200
			});
		});

		$('.feedback-slider', $parent).each((i, el) => {
			new Slider($(el), {
				fixed: true,
				max: 1,
				minwidth: 250
			});
		});

		$('.js-tip', $parent).each(function (e) {
			let $parent = $(this);

			let $tip: JQuery = null;

			$parent.click((e) => {
				let $target = $(e.target);

				let $tb: JQuery = $('.termsbox', $parent);

				let isin = $target.closest($tb).length == 1;

				if (isin) {
					e.preventDefault();
					e.stopPropagation();
				}

				if ($target.is($parent)) {
					e.preventDefault();

					let text = $parent.data('tip');

					$tip = $('\
						<div class="termsbox"> \
							<div class="termsbox__overlay"></div> \
							<div class="termsbox__body"></div> \
						</div>\
					');

					$('.termsbox__body', $tip).html(text);

					$tip.hide();
					$tip.appendTo($parent);
					$tip.fadeIn();
				} else {
					if ($tip != null) {
						let $overlay = $('.termsbox__overlay', $tip);

						if ($target.is($overlay)) {
							$tip.fadeOut(250, () => {
								$tip.remove();
								$tip = null;
							});
						}
					}
				}
			});
		});
	}

	public static init(): void {
		(<any> window).Site = Site;

		$(() => {
			this.discover();
		});
	}
}

(function($) {
	function scrollTo(top, force?: boolean) {
		if (force) {
			$('html,body').scrollTop(top);
		} else {
			$('html,body').animate({
				scrollTop: top
			}, 900);
		}
	}

	function updateScroll() {
		var top = $(window).scrollTop();
		var wy = $(window).outerHeight();
		var dy = $(document).outerHeight();

		$('body').toggleClass('float', top > 0);
		$('body').toggleClass('nofloat', top == 0);

		var $mainmenu = $('.mainmenu');
		var $list = $($('.menu__head', $mainmenu).get().reverse());

		$('.menu__item', $mainmenu).removeClass('menu__item--selected');

		$list.each(function() {
			var $m = $(this);
			var $item = $m.parent();

			var name = $m.data('name');
			var atop = null;

			if (name == "") {
				atop = 0;
			} else {
				var $a = $('a[name="' + name + '"]');
				atop = $a.offset().top;
			}

			if (top > atop - 200 || top > dy - wy - 200) {
				$item.addClass('menu__item--selected');
				return false;
			}
		});
	}

	(<any> window).updateElements = function($parent) {
		var wx = $(window).width();

		(<any> $('.svg-inject', $parent)).svgInject();

		$('.js-gotop', $parent).click(function(e) {
			e.preventDefault();

			scrollTo(0);
		});

		$('.js-godown', $parent).click(function(e) {
			e.preventDefault();

			var $this = $(this);
			var $header = $('.header');

			window.setTimeout(function () {
				var name = $this.data('name');;
				var top = null;

				if (name == "") {
					top = 0;
				} else {
					var $dst = $('[name="' + name + '"]');
					var hh = $header.height();

					top = $dst.offset().top - hh;
				}

				scrollTo(top);
			}, 1);
		});

		(<any> window).Site.discover($parent);
	}

	$(document).ready(function() {
		function initHtml() {
			var $mainmenu=$('.mainmenu');

			$('.menubutton', $mainmenu).click(function(e) {
				e.preventDefault();

				$mainmenu.toggleClass('mainmenu--opened', !$mainmenu.hasClass('mainmenu--opened'))
				$('body').toggleClass('body--menuopened', $mainmenu.hasClass('mainmenu--opened'))
			});

			(<any> window).updateElements();
		}

		function initRwd() {
			$(window).resize(updateScroll);
			$(window).scroll(updateScroll);

			updateScroll();
		}

		initHtml();
		initRwd();
	});

	$(window).load(function() {
		PrivatePolicy.initCookieConsent();

		$('body').addClass('body--loaded');

		setTimeout(function() {
			$('body').removeClass('body--loading');
			$('body').trigger('pageloaded');
		}, 1000);
	});
})(jQuery);

Site.init();
